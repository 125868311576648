<template>
  <v-container
    fluid
    style="height: 100%; margin:0px; padding:0px; min-height: inherit"
    class="d-flex flex-row"
  >
    <div class="w-100 d-flex flex-column pl-5">
      <span class="app-bold-font blue-font-color font-24 pb-1 pt-1">
        Chat
      </span>
      <div>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="searchKey"
              hide-details
              placeholder="Search"
              solo
              flat
              dense
              rounded
              append-icon="mdi-magnify"
              class="mr-15 my-3"
              elevation="0"
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <div style="max-height: 60vh">
            <v-list>
              <v-list-item>
                <v-list-item-title>
                  <v-icon class="mr-2">mdi-magnify</v-icon>Search messages
                  {{ searchKey ? `for "${searchKey}"` : "" }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item v-for="(item, index) in filteredUsers" :key="index">
                <v-list-item-title
                  v-if="item.header"
                  style="font-weight: bold;"
                  >{{ item.header }}</v-list-item-title
                >
                <v-list-item-title
                  v-else
                  @click="selectUser(item)"
                  class="list-user-item"
                >
                  <editable-avatar
                    :editable="false"
                    :image="item.thumbnail ? item.thumbnail : item.image"
                    :size="40"
                    :shadow="false"
                    class="mr-2"
                  />
                  {{ item.name }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </div>
        </v-menu>
      </div>
      <div
        class="shop-list d-flex flex-column mr-5"
        v-if="profile && profile._id"
      >
        <chat-room-card
          :conversationId="chat.uniqueName"
          :users="chat.users"
          v-for="chat in chatRooms"
          :key="chat._id"
        />
      </div>
    </div>
  </v-container>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import EditableAvatar from "../../components/EditableAvatar.vue";
import ChatRoomCard from "./ChatRoomCard.vue";
export default {
  components: { ChatRoomCard, EditableAvatar },
  data() {
    return {
      loading: false,
      errorMessage: null,
      snackbar: false,
      filteredUsers: [],
      searchKey: "",
    };
  },
  methods: {
    ...mapActions("chat", {
      getMyConversations: "getAllConversations",
      searchUsers: "searchUsers",
    }),
    selectUser(item) {
      if (this.$route.query._id != item._id) {
        this.$router.push({
          name: "mobile-chat-room",
          query: { _id: item._id, type: item.type },
        });
      }
    },
    goToAgencyMessages() {
      this.$router.push({ name: "agency-message" });
    },
  },
  computed: {
    ...mapGetters({
      chatRooms: "chat/getConversations",
      profileType: "auth/getType",
    }),
    ...mapState("auth", {
      profile: "profile",
    }),
  },
  watch: {
    searchKey(newValue) {
      if (newValue) {
        this.searchUsers(newValue)
          .then((res) => {
            this.filteredUsers = res;
          })
          .catch((error) => {
            if (error.response) {
              console.log(error.response.data.message);
            } else {
              console.log(error);
            }
          });
      }
    },
  },
};
</script>
<style scoped></style>
