<template>
  <v-card>
    <div class="profile-card-content pa-5">
      <editable-avatar
        :image="profile.image"
        class="mb-1"
        :name="profile.name"
        :editable="false"
      />
      <span
        class="profile-title mt-5 px-2 url-button"
        @click="goToProfile"
        v-if="profile.first_name"
      >
        {{ profile.first_name + " " + profile.last_name }}
      </span>
      <span class="profile-text px-2 mt-3" v-if="profile.address">
        <v-icon color="#6038f7" v-text="'mdi-map-marker-outline'" size="18" />
        {{ profile.address }},
        {{ profile.address2 ? profile.address2 + ", " : "" }} {{ profile.city }}
      </span>
      <span class="px-2 mt-2" style="color: grey; font-size: 12px;">Admin</span>
    </div>
  </v-card>
</template>
<script>
import EditableAvatar from "@/components/EditableAvatar.vue";

export default {
  components: { EditableAvatar },
  props: {
    profile: Object,
    onClicked: Function,
  },
  computed: {
    getCompletedInfo() {
      if (!this.profile._id) return "";
      var str = "";
      if (!this.profile.image) str += "Image";
      if (!this.profile.about_me) str ? (str += ", Image") : (str = "Image");
      if (!this.profile.phone_verified)
        str ? (str += ", Phone not verified") : (str = "Phone not verified");
      if (!this.profile.email_verified || !this.profile.email_verified)
        str ? (str += ", Email not verified") : (str = "Email not verified");
      if (this.profile.interests && this.profile.interests.length == 0) {
        str ? (str += ", Interests not set") : (str = "Interests not set");
      }
      if (this.profile.causes && this.profile.causes.length == 0) {
        str ? (str += ", Causes not set") : (str = "Causes not set");
      }
      if (
        this.profile.availabilities &&
        this.profile.availabilities.length == 0
      ) {
        str
          ? (str += ", Availabilities not set")
          : (str = "Availabilities not set");
      }
      if (!this.profile.phone) str ? (str += ", Phone") : (str = "Phone");
      if (!this.profile.address) str ? (str += ", Address") : (str = "Address");
      if (!this.profile.city) str ? (str += ", City") : (str = "City");
      if (!this.profile.state) str ? (str += ", State") : (str = "State");
      if (!this.profile.zip) str ? (str += ", Zip") : (str = "Zip");

      return str;
    },
  },
  methods: {
    goToProfile() {
      this.$router.push({
        name: "admin-profile",
      });
    },
  },
};
</script>
<style>
.profile-title {
  color: #6038f7;
  font-family: "Poppins-SemiBold";
  font-size: 18px;
  text-align: center;
}
.profile-text {
  color: #6038f7;
  font-family: "Poppins-Medium";
  font-size: 12px;
  text-align: center;
}
.profile-card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}
</style>
