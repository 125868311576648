<template>
  <router-view />
</template>
<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters("auth", {
      profile: "getProfile",
    }),
  },
  watch: {
    "profile._id": function(newValue) {
      if (this.$route.params.id == undefined && newValue != undefined) {
        this.$router.push({
          name: "agency-profile-events",
          params: { id: newValue },
        });
      }
    },
    "$route.name": function(newValue) {
      if (
        newValue == "agency-profile-details" &&
        !(this.$route.params.id == undefined && newValue != undefined)
      ) {
        this.$router.back();
      } else if (newValue == "agency-profile") {
        this.$router.push({
          name: "agency-profile-events",
          params: { id: this.profile._id },
        });
      }
    },
  },
  mounted() {
    if (this.$route.params.id == undefined && this.profile._id != undefined) {
      this.$router.push({
        name: "agency-profile-events",
        params: { id: this.profile._id },
      });
    }
  },
};
</script>
