<template>
  <div class="left-section d-flex flex-column">
    <span
      style="font-family: 'Poppins-SemiBold; color: #5b2cae; font-size: 1.5rem;padding-bottom: 6px; padding-top: 20px;"
    >
      Shop
    </span>
    <div>
      <v-text-field
        hide-details
        placeholder="Search"
        solo
        flat
        dense
        rounded
        append-icon="mdi-magnify"
        class="mr-15 my-3"
        elevation="0"
      />
    </div>
    <div class="shop-list d-flex flex-column">
      <div>
        <v-navigation-drawer
          permanent
          height="120"
          color="transparent"
          width="360"
        >
          <v-list-item-group dense nav v-model="select">
            <v-list-item v-for="item in menus" :key="item.title" link>
              <v-list-item-icon>
                <v-icon v-text="item.icon" />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="item.title" />
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-navigation-drawer>
      </div>
      <span
        style="font-family: 'Poppins-SemiBold; color: #5b2cae; font-size: 18px;padding-bottom: 6px; padding-top: 20px;"
      >
        Categories
      </span>
      <div class="d-flex flex-row my-3" style="align-items: center">
        <div>
          <v-img
            :src="require('@/assets/samples/agency1.png')"
            width="30"
            height="30"
          />
        </div>
        <span style="color: #5b2cae; font-size: 14px; padding-left: 10px;">
          Vehicles
        </span>
      </div>
      <div class="d-flex flex-row my-3" style="align-items: center">
        <div>
          <v-img
            :src="require('@/assets/samples/agency1.png')"
            width="30"
            height="30"
          />
        </div>
        <span style="color: #5b2cae; font-size: 14px; padding-left: 10px;">
          Property Rentals
        </span>
      </div>
      <div class="d-flex flex-row my-3" style="align-items: center">
        <div>
          <v-img
            :src="require('@/assets/samples/agency1.png')"
            width="30"
            height="30"
          />
        </div>
        <span style="color: #5b2cae; font-size: 14px; padding-left: 10px;">
          Apparel
        </span>
      </div>
      <div class="d-flex flex-row my-3" style="align-items: center">
        <div>
          <v-img
            :src="require('@/assets/samples/agency1.png')"
            width="30"
            height="30"
          />
        </div>
        <span style="color: #5b2cae; font-size: 14px; padding-left: 10px;">
          Classifieds
        </span>
      </div>
      <div class="d-flex flex-row my-3" style="align-items: center">
        <div>
          <v-img
            :src="require('@/assets/samples/agency1.png')"
            width="30"
            height="30"
          />
        </div>
        <span style="color: #5b2cae; font-size: 14px; padding-left: 10px;">
          Electronics
        </span>
      </div>
      <div class="d-flex flex-row my-3" style="align-items: center">
        <div>
          <v-img
            :src="require('@/assets/samples/agency1.png')"
            width="30"
            height="30"
          />
        </div>
        <span style="color: #5b2cae; font-size: 14px; padding-left: 10px;">
          Entertainments
        </span>
      </div>
      <div class="d-flex flex-row my-3" style="align-items: center">
        <div>
          <v-img
            :src="require('@/assets/samples/agency1.png')"
            width="30"
            height="30"
          />
        </div>
        <span style="color: #5b2cae; font-size: 14px; padding-left: 10px;">
          Family
        </span>
      </div>
      <div class="d-flex flex-row my-3" style="align-items: center">
        <div>
          <v-img
            :src="require('@/assets/samples/agency1.png')"
            width="30"
            height="30"
          />
        </div>
        <span style="color: #5b2cae; font-size: 14px; padding-left: 10px;">
          Free Stuff
        </span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      menus: [
        {
          title: "Browse all",
          icon: "mdi-view-dashboard",
          routerName: "agency-shop-feeds",
        },
        {
          title: "Your Notification",
          icon: "mdi-bell",
          routerName: "agency-shop-notification",
        },
      ],
      select: 0,
      createDialog: false,
    };
  },
  methods: {},
  watch: {
    select(newValue) {
      if (newValue != undefined) {
        if (this.$route.name != this.menus[newValue].routerName) {
          this.$router.push({
            name: this.menus[newValue].routerName,
          });
        }
      }
    },
  },
  mounted() {
    for (var i = 0; i < this.menus.length; i++) {
      if (this.menus[i].routerName == this.$route.name) {
        this.select = i;
      }
    }
    if (this.select == 0) {
      if (this.$route.name != this.menus[0].routerName) {
        this.$router.push({
          name: this.menus[0].routerName,
        });
      }
    }
  },
};
</script>
<style scoped>
.left-section {
  max-height: 0;
  position: sticky;
  min-height: inherit;
  top: 70px;
  max-width: 360px;
  min-width: 360px;
  width: 360px;
  padding-left: 30px;
}
.shop-list {
  flex-grow: 1;
  overflow-y: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.shop-list:hover {
  flex-grow: 1;
  overflow-y: auto;
  -ms-overflow-style: block; /* IE and Edge */
  scrollbar-width: block; /* Firefox */
}

.left-section .shop-list:hover::-webkit-scrollbar {
  display: block;
  width: 8px;
  transition-duration: 0.5s;
}
.left-section .shop-list::-webkit-scrollbar {
  display: none;
  transition-duration: 0.5s;
}

/* Track */
.left-section .needs-list::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 3px;
}
/* Handle */
.left-section .shop-list::-webkit-scrollbar-thumb {
  background: rgba(240, 160, 0, 0.3);
  border-radius: 3px;
}

/* Handle on hover */
.left-section .shop-list::-webkit-scrollbar-thumb:hover {
  background: #f0a000;
}

div >>> .v-navigation-drawer__border {
  display: none;
}
div >>> .v-item--active {
  color: #f0a000 !important;
}
div >>> .v-item--active .v-icon {
  color: #f0a000 !important;
}
</style>
