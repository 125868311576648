<template>
  <v-card
    :class="{
      'chat-card': true,
      'current-chat':
        otherId == $route.query._id && otherType == $route.query.type,
    }"
    @click="goToMessages"
  >
    <v-card-text class="d-flex flex-row align-center">
      <div>
        <editable-avatar
          :image="user ? (user.thumbnail ? user.thumbnail : user.image) : ''"
          :editable="false"
          :size="50"
        />
      </div>
      <div class="ml-4" style="flex-grow: 1; min-width: 50px">
        <div class="d-flex flex-row" style="justify-content: space-between">
          <span class="username" v-if="user">
            {{ user.name ? user.name : user.first_name + " " + user.last_name }}
          </span>
          <span v-if="lastMessage" style="white-space: nowrap;">
            {{ formatDate(lastMessage.dateCreated) }}
          </span>
        </div>
        <div
          v-if="lastMessage"
          class="mt-1 d-flex flex-row"
          style="justify-content: space-between;"
        >
          <div
            style="text-overflow: ellipsis; overflow: hidden;white-space: nowrap;"
          >
            {{
              lastMessage.media
                ? "Image"
                : messageType == "sticker"
                ? "Sticker"
                : messageBody
            }}
          </div>
          <div
            v-if="unreadCount"
            style="width: 22px; height: 22px; min-width: 21px; border-radius: 11px; background: blue; text-align: center; color: white; font-weight: bold;"
          >
            {{ unreadCount > 10 ? "•" : unreadCount }}
          </div>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import dateFormat from "dateformat";
import EditableAvatar from "@/components/EditableAvatar.vue";
export default {
  props: {
    conversationId: String,
    users: Array,
  },
  components: {
    EditableAvatar,
  },
  data() {
    return {
      other: "",
      otherId: "",
      otherType: "",
      user: null,
      activeConversation: null,
      lastMessage: null,
      unreadCount: null,
      messageType: "text",
      messageBody: "",
    };
  },
  computed: {
    ...mapGetters({
      profile: "auth/getProfile",
      profileType: "auth/getType",
      conversationsClient: "chat/conversationsClient",
      timezone: "usersetting/getTimeZone",
    }),
  },
  watch: {
    users(newValue) {
      if (newValue) {
        this.getUserInfo();
      }
    },
    conversationsClient(newValue) {
      if (newValue) {
        this.getActiveConversation();
      }
    },
  },
  methods: {
    ...mapActions({
      getAgency: "agency/getAgency",
      getVolunteer: "volunteer/getVolunteer",
      getAdmin: "auth/getAdmin",
      initConversationsClient: "chat/initConversationsClient",
    }),
    getUserInfo() {
      if (this.users[0] == this.profileType + "-" + this.profile._id) {
        this.other = this.users[1];
      } else {
        this.other = this.users[0];
      }
      this.otherId = this.other.split("-")[1];
      this.otherType = this.other.split("-")[0];
      if (this.otherType == "Agency") {
        this.getAgency(this.otherId)
          .then((res) => {
            this.user = res;
          })
          .catch((error) => {
            console.log(error.response.data.message);
          });
      } else if (this.otherType == "Volunteer") {
        this.getVolunteer({ _id: this.otherId })
          .then((res) => {
            this.user = res;
          })
          .catch((error) => {
            if (error.response.data.message == "User not found") {
              this.user = {
                name: "Deleted User",
              };
            } else {
              console.log(error.response.data.message);
            }
          });
      } else if (this.otherType == "Admin") {
        this.getAdmin({ _id: this.otherId })
          .then((res) => {
            this.user = res;
          })
          .catch((error) => {
            console.log(error.response.data.message);
          });
      }
    },
    goToMessages() {
      if (
        this.otherId &&
        this.otherType &&
        this.$route.query._id != this.otherId
      ) {
        if (this.$vuetify.breakpoint.smAndDown) {
          this.$router.push({
            name: "mobile-chat-room",
            query: { _id: this.otherId, type: this.otherType },
          });
        } else {
          console.log("~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~");
          this.$router.push({
            name: "chat-details",
            query: { _id: this.otherId, type: this.otherType },
          });
        }
      }
    },
    getActiveConversation() {
      this.conversationsClient
        .getConversationByUniqueName(this.conversationId)
        .then((res) => {
          this.activeConversation = res;
          this.activeConversation
            .getMessages(1)
            .then((newMessages) => {
              if (newMessages.items.length) {
                this.lastMessage = newMessages.items[0];
                this.messageType = this.getType(this.lastMessage.body);
                this.messageBody = this.getBody(this.lastMessage.body);
              }
              this.activeConversation
                .getUnreadMessagesCount()
                .then((res) => {
                  this.unreadCount = res;
                })
                .catch((error) => console.log(error));
            })
            .catch((error) => console.log(error));
          this.activeConversation.on("messageAdded", async (message) => {
            this.lastMessage = message;
            this.messageType = this.getType(this.lastMessage.body);
            this.messageBody = this.getBody(this.lastMessage.body);
            this.activeConversation
              .getUnreadMessagesCount()
              .then((res) => {
                this.unreadCount = res;
              })
              .catch((error) => console.log(error));
          });
          this.activeConversation.on("participantUpdated", () => {
            this.activeConversation
              .getUnreadMessagesCount()
              .then((res) => {
                this.unreadCount = res;
              })
              .catch((error) => console.log(error));
          });
        })
        .catch((error) => {
          console.log("get active conversation failed", error.message);
          if (
            error.message.includes(
              "Unable to connect: Twilsock: request timeout:"
            )
          ) {
            this.initConversationsClient();
          } else if (
            error.message.includes(
              "Unable to connect: Access Token expired or expiration date invalid"
            )
          ) {
            this.initConversationsClient();
          }
        });
    },
    formatDate(date) {
      var today = new Date();
      var todayFormat = "hh:MM TT";
      var weekFormat = "ddd hh:MM TT";
      var dayFormat = "mm/dd/yyyy";
      var diffTime = today.getTime() - date.getTime();
      var diffDay = diffTime / (1000 * 3600 * 24);
      if (diffDay < 1) {
        return dateFormat(this.convertTZ(date, this.timezone), todayFormat);
      } else if (diffDay < 7) {
        return dateFormat(this.convertTZ(date, this.timezone), weekFormat);
      } else {
        return dateFormat(this.convertTZ(date, this.timezone), dayFormat);
      }
    },
    convertTZ(date, tzString) {
      if (!date) return "";
      return new Date(
        (typeof date === "string" ? new Date(date) : date).toLocaleString(
          "en-US",
          {
            timeZone: tzString ? tzString : "America/New_York",
          }
        )
      );
    },
    getType(item) {
      try {
        var obj = JSON.parse(item);
        return obj.type;
      } catch (error) {
        console.log(error.message);
      }
      return "text";
    },
    getBody(item) {
      try {
        var obj = JSON.parse(item);
        return obj.body;
      } catch (error) {
        console.log(error.message);
      }
      return item;
    },
  },
  mounted() {
    this.getUserInfo();
    if (this.conversationsClient) {
      this.getActiveConversation();
    }
  },
};
</script>
<style scoped>
.chat-card {
  height: 80px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.current-chat {
  background: #eef3f8;
}
.username {
  color: #242f36;
  font-size: 14px;
  font-family: "Poppins-Regular";
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.current-chat >>> .username {
  font-weight: bold;
}
</style>
