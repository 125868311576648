<template>
  <v-container
    fluid
    style="height: 100%; margin:0px; padding:0px; min-height: inherit"
    class="d-flex flex-row"
  >
    <agency-shop-menu-section class="hidden-md-and-down" />
    <router-view />
    <v-snackbar v-model="snackbar">
      {{ errorMessage }}
    </v-snackbar>
  </v-container>
</template>
<script>
import { mapActions } from "vuex";
import AgencyShopMenuSection from "./AgencyShopMenuSection.vue";
export default {
  components: {
    AgencyShopMenuSection,
  },
  data() {
    return {
      loading: false,
      errorMessage: null,
      snackbar: false,
    };
  },
  methods: {
    ...mapActions("event", {
      fetchAllEvents: "fetchAllEvents",
    }),
    showErrorMessage(message) {
      this.errorMessage = message;
      this.snackbar = true;
    },
  },
};
</script>
<style scoped></style>
