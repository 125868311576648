<template>
  <v-container
    fluid
    style="height: 100%; margin:0px; padding:0px; min-height: inherit"
    class="d-flex flex-row"
    v-if="$vuetify.breakpoint.mdAndUp"
  >
    <chat-menu-section />
    <router-view />
  </v-container>
</template>
<script>
import ChatMenuSection from "./ChatMenuSection.vue";
export default {
  components: {
    ChatMenuSection,
  },
  data() {
    return {
      loading: false,
      errorMessage: null,
      snackbar: false,
    };
  },
  mounted() {
    if (this.$vuetify.breakpoint.smAndDown) {
      this.$router.push({ name: "mobile-chat" });
    }
  },
};
</script>
<style scoped></style>
